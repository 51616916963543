import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lition-shared-components/build/lib/index.css';
import '../static/styles/globals.css';

import * as SEO from '../services/helpers/seo';

import { isProdAndNotPuppeteerEnv, isShouldLoadHotjarScript, isTestEnv } from '../services/helpers/env';
import { loadGoogleAutoCompleteScript, loadScript } from '../services/helpers/script';

import App from 'next/app';
import { AppContext } from '../services/helpers/context';
import Head from 'next/head';
import ProjectInfo from '../package.json';
import PropTypes from 'prop-types';
import React from 'react';
import { Router } from 'next/router';
import { saveQueryDataToStorage } from '../services/helpers/url/url';
import scrollFix from '../services/helpers/scrollFix';
import { setLoadingStateHandler } from '../components/__mixins__/handlers';

/** @extends React.Component */
class Stromee extends App {
    constructor() {
        super();

        this.state = {
            _isGoogleScriptLoaded: false,
            isLoading: false
        };

        this.loadGoogleAutoCompleteScript = loadGoogleAutoCompleteScript.bind(this);
        this.handleLeavingPage = setLoadingStateHandler.bind(this);
    }

    /**
     * Common logic
     * @fixme: Cover by tests the beforeunload (R)
     */
    componentDidMount() {
        const regex = /(^(?!.*producer).*$)/;
        console.log();
        if (regex.exec(window.location.pathname) && window.location.pathname !== '/404') {
           window.location.assign('/producers');
        }

        window.addEventListener('beforeunload', this.handleLeavingPage);

        scrollFix();

        const {
            pageProps: { content = {} }
            // router: { query = {} } = {}
        } = this.props;
        if (
            !content.disableSystemPageScript && // TODO: Use disableSystemPageScript for pages directly too
            isShouldLoadHotjarScript()
        ) {
            const hotjarScript = '/static/scripts/hotjar.js';
            loadScript(hotjarScript);
        }

        saveQueryDataToStorage();
        this.loadGoogleAutoCompleteScript();
    }

    /**
     * Common logic
     * @fixme: Cover by tests the beforeunload (R)
     */
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleLeavingPage);
    }

    render() {
        const {
            Component,
            pageProps,
            pageProps: { content = {} }
        } = this.props;

        console.info('APP info:', ProjectInfo.name, ProjectInfo.version, new Date());

        const { isLoading, _isGoogleScriptLoaded } = this.state;

        return (
            <>
                <Head>
                    {content.disableSystemHeadPageTags ? null : (
                        <>
                            <title>{SEO.title}</title>

                            <link rel="preconnect" href="https://kundenportal.stromee.de" crossOrigin="true" />
                            <link rel="preconnect" href="https://images.ctfassets.net" crossOrigin="anonymous" />
                            <link rel="preconnect" href="https://cdnjs.cloudflare.com" crossOrigin="anonymous" />

                            <link rel="preconnect" href="https://www.googletagmanager.com" crossOrigin="anonymous" />

                            {/* TODO: configure font preload only when used on page @Pavel */}
                            {/* TODO: convert all fonts to modern format @Pavel */}
                            <link
                                rel="preload"
                                href="/static/gilroy/Gilroy-Bold.otf"
                                as="font"
                                crossOrigin="anonymous"
                            />
                            <link
                                rel="preload"
                                href="/static/gilroy/Gilroy-Semibold.woff2"
                                as="font"
                                type="font/woff2"
                                crossOrigin="anonymous"
                            />
                            <link
                                rel="preload"
                                href="/static/gilroy/Gilroy-Regular.otf"
                                as="font"
                                crossOrigin="anonymous"
                            />
                            <link
                                rel="preload"
                                href="/static/gilroy/Gilroy-Medium.woff2"
                                as="font"
                                type="font/woff2"
                                crossOrigin="anonymous"
                            />
                            <link
                                rel="preload"
                                href="/static/gilroy/Gilroy-Light.otf"
                                as="font"
                                crossOrigin="anonymous"
                            />
                            <link
                                rel="preload"
                                href="/static/gilroy/Gilroy-RegularItalic.otf"
                                as="font"
                                crossOrigin="anonymous"
                            />
                            <link
                                rel="preload"
                                href="/static/gilroy/Gilroy-SemiboldItalic.otf"
                                as="font"
                                crossOrigin="anonymous"
                            />
                        </>
                    )}
                    {(isProdAndNotPuppeteerEnv() || isTestEnv()) && (
                        <>
                            <script defer src="/static/scripts/newrelic.js" type="text/javascript" />
                            <script defer src="/static/scripts/setErrorDataToNewrelic.js" type="text/javascript" />
                        </>
                    )}
                    {!content.disableSystemPageScript && // FIXME: Check the iframe-resizer, use by npm? or just when iframe is true? @Pavel
                    (isProdAndNotPuppeteerEnv() || isTestEnv()) && ( // FIXME: Should we add "async" for all script tags?
                        <>
                            <script
                                defer
                                src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.1.1/iframeResizer.contentWindow.js"
                            />
                        </>
                    )}
                </Head>
                <AppContext.Provider value={{ isAppGoogleScriptLoaded: _isGoogleScriptLoaded }}>
                    <Component isLoading={isLoading} {...pageProps} />
                </AppContext.Provider>
            </>
        );
    }
}

Stromee.propTypes = {
    pageProps: PropTypes.shape({
        content: PropTypes.shape({
            disableSystemHeadPageTags: PropTypes.bool,
            disableSystemPageScript: PropTypes.bool,
            widgets: PropTypes.arrayOf(PropTypes.string)
        }),
        query: PropTypes.shape({})
    }).isRequired,

    Component: PropTypes.elementType.isRequired,
    router: PropTypes.shape({
        asPath: PropTypes.string
    }).isRequired
};

export default Stromee;
