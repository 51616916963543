import React from 'react';
import { SITE_URL, PAGE_ID, STANDARD_STROMEE_BUSINESS_CHANNEL } from '../../../constants';

import { isStagingEnv } from '../env';
import {
    isContractPage,
    isProducerDetailsPage,
    isProducersPage,
    isSignUpPage,
    isThankYouPage,
    getPagePathWithoutQueryParams
} from '../url/url';

export function getRobotsTag(pageUrl = '', query = {}, landingPageDetails = {}) {
    const robotsContent = getRobotsTagContent(pageUrl, query, landingPageDetails);

    return <meta name="robots" content={robotsContent} />;
}

// eslint-disable-next-line no-unused-vars
export function getRobotsTagContent(pageUrl = '', query = {}, landingPageDetails = {}) {
    // TODO: check this values, looks like 'index' is not correct (may be 'all'?)
    const index = 'index, follow';
    const noIndex = 'noindex';

    // exclude standard url name from the showInSitemap logic
    const showInSitemap = landingPageDetails.name !== PAGE_ID.STANDARD ? landingPageDetails.showInSitemap : true;

    if (isStagingEnv()) {
        return noIndex;
    }
    if (isSignUpPage(pageUrl) || isThankYouPage(pageUrl) || isContractPage(pageUrl)) {
        return noIndex;
    }
    if (!showInSitemap) {
        return noIndex;
    }
    if (!pageUrl) {
        // Be careful with this value, because in the next.js 11 it is undefined
        return noIndex;
    }
    if (isProducersPage(pageUrl) && pageUrl.includes('?')) {
        return noIndex;
    }
    if (isProducerDetailsPage(pageUrl) && pageUrl.includes('?')) {
        return noIndex;
    }

    return index;
}

export function getCanonical(pageUrl = '', query = {}, landingPageDetails = {}) {
    if (isStagingEnv()) {
        return null;
    }
    if (landingPageDetails.businessChannel !== STANDARD_STROMEE_BUSINESS_CHANNEL) {
        return null;
    }
    if (isSignUpPage(pageUrl) || isThankYouPage(pageUrl) || isContractPage(pageUrl)) {
        return null;
    }
    if (isProducersPage(pageUrl) && pageUrl.includes('?')) {
        return null;
    }
    if (isProducerDetailsPage(pageUrl) && pageUrl.includes('?')) {
        return null;
    }

    const canonicalLinkHref = getCanonicalLinkHref(query, pageUrl);

    return <link rel="canonical" href={canonicalLinkHref} />;
}

// eslint-disable-next-line no-unused-vars
export function getCanonicalLinkHref(query = {}, pageUrl = '') {
    if (!pageUrl) {
        return SITE_URL;
    }

    const pagePathWithoutQueryParams = `${SITE_URL}${getPagePathWithoutQueryParams(pageUrl)}`;
    return pagePathWithoutQueryParams;
}

export const title = 'stromee | Dein digitaler Marktplatz für erneuerbare Energien.';

export const seoMetaTags = (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
        {/* TODO: Move the default SEO meta tags values to JSON config in DB or to CMS for each page in future @Pavel */}

        {/* Primary Meta Tags */}
        <meta name="title" content="stromee | Dein digitaler Marktplatz für erneuerbare Energien." />
        <meta
            name="description"
            content="stromee verbindet Verbraucher direkt mit Erzeugern erneuerbarer Energien. Ab jetzt kommt dein Ökostrom aus der Community. Einfach, fair und preiswert."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://stromee.de/" />
        <meta property="og:title" content="stromee | Dein digitaler Marktplatz für erneuerbare Energien." />
        <meta
            property="og:description"
            content="stromee verbindet Verbraucher direkt mit Erzeugern erneuerbarer Energien. Ab jetzt kommt dein Ökostrom aus der Community. Einfach, fair und preiswert."
        />
        <meta
            property="og:image"
            content="//images.ctfassets.net/4gjysvsltb3v/5IbxrnfnhRqz4xvctSeTaH/1d66500b29b5f78901638775b78941d6/Lition_Keyvisual_1200x627-02.jpg"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://stromee.de/" />
        <meta property="twitter:title" content="stromee | Dein digitaler Marktplatz für erneuerbare Energien." />
        <meta
            property="twitter:description"
            content="stromee verbindet Verbraucher direkt mit Erzeugern erneuerbarer Energien. Ab jetzt kommt dein Ökostrom aus der Community. Einfach, fair und preiswert. "
        />
        <meta
            property="twitter:image"
            content="//images.ctfassets.net/4gjysvsltb3v/5IbxrnfnhRqz4xvctSeTaH/1d66500b29b5f78901638775b78941d6/Lition_Keyvisual_1200x627-02.jpg"
        />
    </>
);
