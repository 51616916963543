import get from 'lodash/get';
import { GOOGLE_MAPS_API_KEY, DEFAULT_LOCALE } from '../../constants';

// TODO: Add tests
// TODO: decrease attributes count, looks like 'defer' is redundant?
export function loadScript(src, async = true, defer = true, type = 'text/javascript', id, cb) {
    const loadedScript = document.getElementById(id || src);

    if (!loadedScript) {
        const loadingScript = document.createElement('script');
        loadingScript.id = id || src;
        loadingScript.type = type;
        loadingScript.src = src;
        loadingScript.async = async;
        loadingScript.defer = defer;

        document.head.appendChild(loadingScript);

        if (cb) {
            loadingScript.onload = cb;
        }
    }
}

// TODO: cover by tests (R)
export function loadGoogleAutoCompleteScript() {
    const { pageProps } = this.props;
    const language = get(pageProps, 'content.landingDetails.language', DEFAULT_LOCALE);
    const callbackName = 'googleMapsOnLoad';
    const googleApi = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry&language=${language}&callback=${callbackName}`;

    loadScript(googleApi, false, true, undefined, 'googleAutoCompleteLibrary');
    window[callbackName] = () => this.setState({ _isGoogleScriptLoaded: true });
}
