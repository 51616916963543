let isScrolledByUser = false;

const scrollFix = () => {
    if (!window.location.hash || isScrolledByUser || !document.querySelector(window.location.hash)) {
        return;
    }
    const { top } = document.querySelector(window.location.hash).getBoundingClientRect();
    window.scrollTo(0, top + document.documentElement.scrollTop);
};

// scroll to anchor manually (browsers loose anchor position after loading dynamic content)
export default () => {
    // required for hash links on page
    window.addEventListener('hashchange', () => {
        // wait for builtin scroll effect
        setTimeout(scrollFix, 1000);
    });

    window.addEventListener('DOMContentLoaded', () => {
        // required for fast connections when images are loaded before DOMContentLoaded
        // but still need some time to render page and animate builtin scroll effect
        setTimeout(scrollFix, 1000);

        // required for moderate connections to back scroll position of hash
        document.querySelectorAll('img').forEach(img => {
            img.addEventListener('load', scrollFix);
        });

        // required for slow connections when user have already started scrolling
        window.addEventListener('mousewheel', () => {
            isScrolledByUser = true;
        });
        window.addEventListener('keydown', () => {
            isScrolledByUser = true;
        });
        window.addEventListener('touchmove', () => {
            isScrolledByUser = true;
        });

        // when scrolltop button clicked
        window.addEventListener('click', () => {
            isScrolledByUser = true;
        });
    });
};
